
import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './Jak_efektivně_5_kg.png';
import styles from './post-grid.module.css';

const JakRychleZhubnout = () => {
    return (
      <div className={styles.wrapper}>
        <SEO
          title={"Jak efektivně a rychle zhubnout 5 kg? | NutritionPro"}
          description={"Jak rychle zhubnout 5 kilo? Jde to, jen musíte změnit svůj životní styl a začít trochu sportovat. Ve výsledku můžete zhubnout ještě daleko víc."}
        />
        <Container>
          <Breadcrumbs style={{ margin: "24px 0" }}>
            <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Jak efektivně a rychle zhubnout 5 kg?
            </BreadcrumbsItem>
          </Breadcrumbs>
        </Container>
        <Container>
          <div className={styles.postGrid}>
            <div>
              <Hero
                title="Jak efektivně a rychle zhubnout 5 kg?"
                date="16.05.22"
              />
              <div>
                <p className={styles.postText}>
                Udržet si krásnou a štíhlou postavu, je dlouhodobý proces. Ovšem <b>zhubnout nemusí být tak složité, jak se na první pohled může zdát</b>. Ukážeme vám, jaké jednoduché tipy byste měli dodržet, abyste dokázali zdravě zhubnout a zároveň to netrvalo až příliš dlouho. Vždy záleží na počáteční váze, ze které hubnete, ale <b>naše tipy vám pomohou vždy</b>.
                </p>
                <img
                  src={img1}
                  className={styles.postImg}
                  style={{ marginBottom: "40px" }}
                  alt="img1"
                />                
                <h5 className={styles.postTitle}>
                Neskočte na nesmyslné diety
                </h5>
                <p className={styles.postText}>
                Některé diety nejsou zrovna <b>šetrné pro vaše zdraví</b>, jiné jsou často nesmyslné. <b>Nesnažte se držet takové diety, které se vám zdají zvláštní a nedůvěryhodné</b>. Lidé v zoufalství zkouší mnoho věcí, ale drastickými dietami si můžete ublížit a rozhodit svůj metabolismus. Odteď žádné diety z Instagramu, měsíční detox, půst a podobně. Raději se <b>poraďte s nutričním specialistou</b>, který vám sestaví jídelníček na míru.
                </p>
                <h5 className={styles.postTitle}>
                Vyzkoušejte program hubnutí od NutritionPro
                </h5>
                <p className={styles.postText}>
                Věděli jste, že s NutritionPro můžete <b>zhubnout bez hladovění? Vyzkoušejte <a href='https://nutritionpro.cz/products/product-1/'>hubnoucí program</a> s jídelníčkem na míru vašim cílům</b>. Je to způsob zdravého hubnutí, který je založený na nejnovějších poznatcích ze světa dietologie. Sestavení jídelníčku probíhá až po <b>kontrole dietologem</b>. Jednoduše si navíc můžete zvolit rychlost hubnutí. 
                </p>
                <ul className={styles.postList}>
                  <li><strong>3</strong> nebo <strong>5chodové menu</strong></li>
                  <li><strong>jídlo na celý týden</strong></li>
                  <li><strong>rozvoz po Praze</strong></li>
                  <li><strong>prémiové suroviny</strong></li>
                </ul>
                <h5 className={styles.postTitle}>
                Začněte sportovat alespoň 3krát týdně
                </h5>
                <p className={styles.postText}>
                Zhubnout rychle 5 kg jde i bez sportu, a to výraznou úpravou stravy. Ovšem se sportem je hubnutí efektivnější a uděláte navíc něco pro své fyzické a duševní zdraví. Zkuste alespoň <b>3krát týdně zařadit sport alespoň na 40 minut</b>. Například chůzi, běh, kolo nebo jiné sporty. Spálené kalorie se projeví na vašem kalorickém deficitu a hubnutí vám půjde lépe. Navíc bude takové hubnutí zdravější.
                </p>
                <p><em>
                Pozor, abyste se díky sportu nedostali až příliš do kalorického deficitu. Extrémy nemají se zdravým hubnutím nic společného. Ideální kalorický deficit pro rychlé hubnutí je maximálně 20 %. </em>
                </p>
                <h5 className={styles.postTitle}>
                Prohlédněte si další osvědčené tipy, jak rychle zhubnout 5 kg
                </h5>
                <p className={styles.postText}>
                Abyste skutečně zaznamenali změny na své váze, doporučujeme zařadit i další <b>osvědčené tipy na rychlé a zdravé hubnutí</b>. Můžete s nimi ještě více podpořit spalování tuků. Jsou snadnější, než by se mohlo na první pohled zdát a mnoho z nich pravděpodobně už dodržujete. 
                </p>
                <ul className={styles.postList}>
                  <li>Dbejte na to, abyste ve svém jídelníčku měli <strong>dostatek zeleniny</strong>.</li>
                  <li><strong>Zvyšte podíl bílkovin</strong>, které vás na delší dobu zasytí.</li>
                  <li>Nezapomínejte <strong>pravidelně a dostatečně pít.</strong></li>
                  <li>Vyhněte se <strong>slazeným nápojům.</strong></li>
                  <li><strong>Omezte pití alkoholu.</strong></li>
                  <li>Dopřejte si <strong>kvalitní a dostatečně dlouhý spánek.</strong></li>
                </ul>
                <h5 className={styles.postTitle}>Pozor na jojo efekt</h5>
                <p className={styles.postText}>
                Jojo efekt neboli opětovné zvýšení hmotnosti po předchozí dietě je poměrně <b>častý problém</b>. Nastává při velmi nízkém energetickém příjmu nebo při vyřazení některé důležité živiny. Jakmile se potom vrátíte do normálního stravovacího režimu, tělo si začne ukládat zásoby.  
                </p>
                <p className={styles.postText}>
                Navíc zvýšení hmotnosti je zpravidla vyšší než to, co se vám podařilo zhubnout. Nezoufejte, <b>jojo efektu se můžete vyhnout, jen se nesmíte vrátit ke svému dřívějšímu nezdravému životnímu stylu</b> a stravování. Zabráníte tím tělu hromadit si zásoby na horší časy a zbavíte se tak viditelných tuků. 
                </p>
              </div>
            </div>
            <PostSidebar />
          </div>
        </Container>
      </div>
    )
  }
  
  export default JakRychleZhubnout